const SymposiumItem = ({
  id,
  slider_photo,
  link_youtube,
  title,
  event_date,
  event_time_start,
  event_time_end,
}) => {
  return (
    <div className='mx-3'>
      <div className='caption-symposium'>
        <iframe
          // width='515'
          height='252'
          style={{width: '100%'}}
          src={link_youtube}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; encrypted-media; gyroscope;'
          referrerPolicy='strict-origin-when-cross-origin'
          allowfullscreen
        />
        <div
          className='d-flex align-items-center justify-content-center py-3'
          style={{
            padding: '0px 75px 0px 75px',
          }}
        >
          <h2 className='fs-6'>{title}</h2>
        </div>
      </div>
    </div>
  )
}

export default SymposiumItem
