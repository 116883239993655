import {QrScanner} from '@yudiel/react-qr-scanner'
import React from 'react'

/**
 *
 * @param {{onSuccessScan:(result:string)=>void}} param0
 * @returns
 */
const QrcodePlugin = ({onSuccessScan}) => {
  return (
    <QrScanner
      onDecode={(result) => {
        onSuccessScan(result)
      }}
      onError={(error) => {}}
    />
  )
}

export default QrcodePlugin
