import {Outlet} from 'react-router-dom'

const Root = () => {
  return (
    <section id='content-body'>
      <Outlet />
    </section>
  )
}

export default Root
