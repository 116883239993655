import {useRef} from 'react'
import {asaKreatifLogoWhite} from '../assetConstants'

const Footer = () => {
  const modalBtnRef = useRef()
  return (
    <footer>
      <div className='container-fluid'>
        <div className='d-flex row mb-2'>
          <div className='col-3'>
            <img src={asaKreatifLogoWhite} />
          </div>
        </div>
        <p className='mb-3 justify-content-start flex-wrap'>
          The Manhattan Square Building, Mid Tower, 12th Floor Kav. 1S,<br/>Jl TB Simatupang, CIlandak Timur, Jakarta Selatan, 12560
        </p>
        <div className='d-flex row'>
          <div className='col-7'>
            <p>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                data-lucide='phone'
                className='lucide lucide-phone'
              >
                <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'></path>
              </svg>{' '}
              +62-813 11654640
            </p>
            <p>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                stroke='currentColor'
                stroke-width='0.2'
                stroke-linecap='round'
                stroke-linejoin='round'
                // data-lucide='phone'
                // className='lucide lucide-phone'
              >
                <path d='M14.875 3.09168C14.111 2.31995 13.201 1.70807 12.198 1.29172C11.1951 0.875371 10.1193 0.662893 9.03333 0.666676C4.48333 0.666676 0.774995 4.37501 0.774995 8.92501C0.774995 10.3833 1.15833 11.8 1.87499 13.05L0.708328 17.3333L5.08333 16.1833C6.29166 16.8417 7.64999 17.1917 9.03333 17.1917C13.5833 17.1917 17.2917 13.4833 17.2917 8.93334C17.2917 6.72501 16.4333 4.65001 14.875 3.09168ZM9.03333 15.7917C7.79999 15.7917 6.59166 15.4583 5.53333 14.8333L5.28333 14.6833L2.68333 15.3667L3.37499 12.8333L3.20833 12.575C2.52312 11.4808 2.15928 10.216 2.15833 8.92501C2.15833 5.14167 5.24166 2.05834 9.02499 2.05834C10.8583 2.05834 12.5833 2.77501 13.875 4.07501C14.5146 4.71165 15.0214 5.46889 15.3662 6.30286C15.7109 7.13683 15.8867 8.03093 15.8833 8.93334C15.9 12.7167 12.8167 15.7917 9.03333 15.7917ZM12.8 10.6583C12.5917 10.5583 11.575 10.0583 11.3917 9.98334C11.2 9.91668 11.0667 9.88334 10.925 10.0833C10.7833 10.2917 10.3917 10.7583 10.275 10.8917C10.1583 11.0333 10.0333 11.05 9.825 10.9417C9.61666 10.8417 8.95 10.6167 8.16666 9.91668C7.54999 9.36668 7.14166 8.69168 7.01666 8.48334C6.89999 8.27501 6.99999 8.16668 7.10833 8.05834C7.19999 7.96667 7.31666 7.81667 7.41666 7.70001C7.51666 7.58334 7.55833 7.49167 7.625 7.35834C7.69166 7.21667 7.65833 7.10001 7.60833 7.00001C7.55833 6.90001 7.14166 5.88334 6.97499 5.46668C6.80833 5.06668 6.63333 5.11668 6.50833 5.10834H6.10833C5.96666 5.10834 5.74999 5.15834 5.55833 5.36668C5.37499 5.57501 4.84166 6.07501 4.84166 7.09168C4.84166 8.10834 5.58333 9.09168 5.68333 9.22501C5.78333 9.36668 7.14166 11.45 9.20833 12.3417C9.7 12.5583 10.0833 12.6833 10.3833 12.775C10.875 12.9333 11.325 12.9083 11.6833 12.8583C12.0833 12.8 12.9083 12.3583 13.075 11.875C13.25 11.3917 13.25 10.9833 13.1917 10.8917C13.1333 10.8 13.0083 10.7583 12.8 10.6583Z' fill='white'/>
              </svg>{' '}
              +62-813 11654640
            </p>
            <p>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                data-lucide='mail'
                className='lucide lucide-mail'
              >
                <rect width='20' height='16' x='2' y='4' rx='2'></rect>
                <path d='m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7'></path>
              </svg>{' '}
              marketing@asakreasi.com
            </p>
          </div>
          <div className='col-5'>
            <p>Follow us</p>
            <p>
              <a href='https://www.instagram.com/asakreasi/'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  data-lucide='instagram'
                  className='lucide lucide-instagram'
                >
                  <rect width='20' height='20' x='2' y='2' rx='5' ry='5'></rect>
                  <path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z'></path>
                  <line x1='17.5' x2='17.51' y1='6.5' y2='6.5'></line>
                </svg>
              </a>
              <a href='https://www.linkedin.com/company/pt-asa-kreasi-interasia/'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  data-lucide='linkedin'
                  className='lucide lucide-linkedin'
                >
                  <path d='M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z'></path>
                  <rect width='4' height='12' x='2' y='9'></rect>
                  <circle cx='4' cy='4' r='2'></circle>
                </svg>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className='copyright'>Copyright © 2023 ASA KREASI</div>
    </footer>
  )
}

export default Footer
