import axios from 'axios'

const url = process.env.REACT_APP_API_URL
const apiKey = process.env.REACT_APP_API_KEY

export const api = axios.create({
  baseURL: url,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'api-key': apiKey,
  },
})
export const apiWithToken = axios.create({
  baseURL: url,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'api-key': apiKey,
    token: localStorage.getItem('token'),
  },
})

export const setupInterceptors = () => {
  apiWithToken.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.message === 'canceled') return
      if (error.response?.status === 401) {
        localStorage.clear()
        window.location.href = '/login'
      }

      return Promise.reject(error)
    },
  )
}

export const apiForm = axios.create({
  baseURL: url,
  crossDomain: true,
  // redirect: "follow",
  // referrerPolicy: "no-referrer",
  headers: {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data',
    'api-key': apiKey,
    app_type: 'MEMBER',
  },
})

export const apiFormCustomHeader = (headers) => {
  return axios.create({
    baseURL: url,
    crossDomain: true,
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
      'api-key': apiKey,
      app_type: 'MEMBER',
      ...headers,
    },
  })
}

export default api
