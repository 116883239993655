import Loader from 'components/Loader'
import dayjs from 'dayjs'
import useSession from 'hooks/useSession'
import {useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

const QrHandler = () => {
  const {salesId} = useParams()
  const {setCookie} = useSession()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const handleSalesId = useCallback(async () => {
    await new Promise((resoleve) => {
      setCookie('sales_promotion_id', salesId, {
        expires: dayjs().add(1, 'month').toDate(),
      })
      setTimeout(() => {
        resoleve('')
      }, 500)
    })
    setLoading(false)

    const timout = setTimeout(() => {
      navigate('/')
    }, 500)

    return () => {
      clearTimeout(timout)
    }
  }, [navigate, salesId, setCookie])

  useEffect(() => {
    handleSalesId()
  }, [handleSalesId])

  return <Loader loading={loading} />
}

export default QrHandler
