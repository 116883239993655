import {asaKreatifLogoRed} from 'assetConstants'

const Loader = ({loading}) => {
  return (
    <div className='vh-100 w-100 d-flex flex-column justify-content-center align-items-center'>
      <img src={asaKreatifLogoRed} width={100} alt='' />
      <div className='progress w-75 mt-2' style={{height: 10}}>
        <div
          className={
            'progress-bar progress-bar-striped bg-danger ' +
            (loading ? 'progress-bar-animated' : '')
          }
          role='progressbar'
          aria-valuenow={loading ? '75' : '100'}
          aria-valuemin='0'
          aria-valuemax='100'
          style={{width: loading ? '75%' : '100%'}}
        />
      </div>
    </div>
  )
}

export default Loader
