import {useQuery} from '@tanstack/react-query'
import api from 'service/core'

export const useProductBannerLists = () =>
  useQuery(
    ['product-banner-lists'],
    () =>
      new Promise((resolve, reject) =>
        api
          .get('pub/product-banner/lists')
          .then((response) => resolve(response?.data?.data))
          .catch((error) => reject(error)),
      ),
  )
