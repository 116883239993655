import {yupResolver} from '@hookform/resolvers/yup'
import cx from 'clsx'
import useSession from 'hooks/useSession'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {phoneRegex} from '../../constants'

const validation = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Full Name must be at least 3 characters'),
  company: Yup.string().required('Office is required'),
  email: Yup.string().email('Email not valid').required('Email is required'),
  phone: Yup.string()
    .required('Whatsapp number is required')
    .test('validatephone', 'Whatsapp number is not valid', (value) =>
      phoneRegex.exec(value),
    ),
  sales_promotion_id: Yup.string(),
})

const RegisterPage = () => {
  const navigate = useNavigate()
  const {sales_promotion_id} = useSession()
  const {
    handleSubmit,
    formState: {errors},
    register,
    reset,
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      company: '',
      sales_promotion_id,
    },
    mode: 'all',
  })

  const {registerData, setCookie} = useSession()

  const handleOnsubmit = async (values) => {
    const cookieData = registerData ? {...registerData, ...values} : values
    setCookie('registerData', cookieData)
    navigate('/survey')
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      reset({
        name: registerData?.name || '',
        email: registerData?.email || '',
        phone: registerData?.phone || '',
        company: registerData?.company || '',
      })
    }

    return () => {
      mounted = false
    }
  }, [])

  return (
    <section className='pendaftaran'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='nav-top-inside'>
              <a href='/'>
                <i className='icon-chevron_left'></i>
              </a>
              <h2>Registration form</h2>
            </div>

            <div className='form-pendaftaran'>
              <p>
                Please complete the following information to finalize your
                registration process.
              </p>
              <form onSubmit={handleSubmit(handleOnsubmit)}>
                <div className='form-group'>
                  <label>
                    Full Name <span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3'>
                    <span className='input-group-text' id='basic-addon1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        dataLucide='user-2'
                        className='lucide lucide-user-2'
                      >
                        <circle cx='12' cy='8' r='5'></circle>
                        <path d='M20 21a8 8 0 1 0-16 0'></path>
                      </svg>
                    </span>
                    <input
                      placeholder='Enter your full name'
                      className='form-control'
                      {...register('name')}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label>
                    Company<span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3 has-validation'>
                    <span className='input-group-text' id='basic-addon1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        dataLucide='hotel'
                        className='lucide lucide-hotel'
                      >
                        <path d='M18 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Z'></path>
                        <path d='m9 16 .348-.24c1.465-1.013 3.84-1.013 5.304 0L15 16'></path>
                        <path d='M8 7h.01'></path>
                        <path d='M16 7h.01'></path>
                        <path d='M12 7h.01'></path>
                        <path d='M12 11h.01'></path>
                        <path d='M16 11h.01'></path>
                        <path d='M8 11h.01'></path>
                        <path d='M10 22v-6.5m4 0V22'></path>
                      </svg>
                    </span>
                    <input
                      type='text'
                      placeholder='Enter your company name'
                      aria-label='Company'
                      aria-describedby='basic-addon1'
                      className={cx('form-control', {
                        'is-invalid': !!errors.company,
                      })}
                      {...register('company')}
                    />
                    <div className='invalid-feedback'>
                      {errors.company?.message}
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label>
                    Email Address<span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3 has-validation'>
                    <span className='input-group-text' id='basic-addon1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        dataLucide='mail'
                        className='lucide lucide-mail'
                      >
                        <rect width='20' height='16' x='2' y='4' rx='2'></rect>
                        <path d='m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7'></path>
                      </svg>
                    </span>
                    <input
                      type='text'
                      placeholder='Enter you email address'
                      aria-describedby='basic-addon1'
                      className={cx('form-control', {
                        'is-invalid': !!errors.email,
                      })}
                      {...register('email')}
                    />
                    <div className='invalid-feedback'>
                      {errors.email?.message}
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label>
                    No. Whatsapp/Phone <span className='mandatory'>*</span>
                  </label>
                  <div className='input-group mb-3 has-validation'>
                    <span className='input-group-text' id='basic-addon1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        dataLucide='phone'
                        className='lucide lucide-phone'
                      >
                        <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'></path>
                      </svg>
                    </span>
                    <input
                      type='text'
                      placeholder='Enter your phone number'
                      aria-label='Username'
                      aria-describedby='basic-addon1'
                      className={cx('form-control', {
                        'is-invalid': !!errors.phone,
                      })}
                      {...register('phone')}
                    />
                    <div className='invalid-feedback'>
                      {errors.phone?.message}
                    </div>
                  </div>
                  <p>
                    We require your WhatsApp number for registration
                    verification.
                  </p>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary border-0 w-100'
                  style={{zIndex: 3}}
                >
                  Next
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegisterPage
