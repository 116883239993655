import affiliate from 'assets/img/affiliate.png'
import IQI from 'assets/img/iqi.png'
import KDI from 'assets/img/kdi.png'
import asaLgLogo from 'assets/img/logo-asa-kreasi-lg.png'
import logo_1 from 'assets/img/logo-klien/logo_1.png'
import logo_10 from 'assets/img/logo-klien/logo_10.png'
import logo_2 from 'assets/img/logo-klien/logo_2.png'
import logo_3 from 'assets/img/logo-klien/logo_3.png'
import logo_5 from 'assets/img/logo-klien/logo_5.png'
import logo_6 from 'assets/img/logo-klien/logo_6.png'
import logo_7 from 'assets/img/logo-klien/logo_7.png'
import logo_8 from 'assets/img/logo-klien/logo_8.png'
import logo_9 from 'assets/img/logo-klien/logo_9.png'
import PON from 'assets/img/pondasi.png'
import RK from 'assets/img/rk.png'
import {useProductBannerLists} from 'hooks/query/useProductBanner'

const members = [RK, KDI, PON, IQI]

const Arrow = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    dataLucide='arrow-right'
    class='lucide lucide-arrow-right'
  >
    <path d='M5 12h14'></path>
    <path d='m12 5 7 7-7 7'></path>
  </svg>
)

const ProductItem = ({description, id, image, link, title}) => (
  <div
    className='d-flex service-list my-3 pe-3'
    onClick={() => {
      const url = link
      window.open(url, '_blank', 'noopener,noreferrer')
    }}
  >
    <div className='img-container d-flex align-items-center'>
      <div className='shadow-light' />
      <img width={90} src={image} className='img-fluid' alt='' />
    </div>
    <div className='position-relative'>
      <h4 className=''>{title}</h4>
      <p>{description}</p>
    </div>
  </div>
)

const BottomHomeSection = () => {
  const {data} = useProductBannerLists()

  return (
    <section>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 pb-4 text-center'>
            <div style={{maxWidth: 130}} className='mx-auto mb-2'>
              <img src={asaLgLogo} className='img-fluid' alt='' />
            </div>
            <h2>
              Asa Kreasi Interasia is committed to exploring the limitless
              opportunities that emerge when imagination connect with
              cutting-edge technology, thus excelling at the intersection of
              Cretivity and Technology
            </h2>
            <p>
              Support by a team of skilled profesionals and experts, we <br />
              strive to create exceptional outcomes
            </p>

            {data?.map((data) => (
              <ProductItem key={data?.id} {...data} />
            ))}

            <p>
              With combining{' '}
              <span className='fw-bold'>Creative and Technology</span>, Indeed
              with help from our strategic partner with their professional
              expertise in various area, ASA focuses on bringing creative
              ideation and digital solutions for your any kind of problem.
            </p>
            <br />
            <div
              className='d-flex flex-column bg-light px-4 py-3 text-dark justify-content-center'
              style={{
                boxShadow: '0px 4px 4px 0px #00000040',
                borderRadius: 23,
              }}
            >
              <p className='fw-normal text-center'>Visit our member group</p>
              <div
                className='d-flex align-items-center mt-2 justify-content-between'
                style={{height: 50}}
              >
                {members.map((v, i) => (
                  <img key={i} src={v} alt={i} className='h-100' />
                ))}{' '}
              </div>
            </div>
            <br />
            <div className='mt-3 mb-5'>
              <p className='text-center fw-bold mb-2'>Affiliate With</p>
              <img src={affiliate} alt='affiliate' style={{maxWidth: 150}} />
            </div>
            <br />
            <h2>Work Experience</h2>
            <p>
              Meet our clients. We approach each client with dedication at the
              core of every engagement, achieving great successes and building
              lasting relationships.
            </p>
            <div className='slider-logo'>
              <div className='slide-logo-track'>
                <div className='slide'>
                  <img src={logo_6} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_7} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_8} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_9} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_10} alt='' />
                </div>

                <div className='slide'>
                  <img src={logo_1} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_2} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_3} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_5} alt='' />
                </div>
              </div>
            </div>
            <div className='slider-logo'>
              <div className='slide-logo-track'>
                <div className='slide'>
                  <img src={logo_1} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_2} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_3} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_5} alt='' />
                </div>

                <div className='slide'>
                  <img src={logo_6} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_7} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_8} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_9} alt='' />
                </div>
                <div className='slide'>
                  <img src={logo_10} alt='' />
                </div>
              </div>
            </div>
            <hr />
            <br />
            <br />
            <h6>Innovate with us</h6>
            <h2 className='my-3'>
              A collaboration with Asa leads you
              <br /> to your success
            </h2>
            <a
              href='https://asakreasi.com/'
              target='_blank'
              className='btn btn-primary'
            >
              Visit our Website <Arrow />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BottomHomeSection
