import NotFoundPage from 'pages/NotFoundPage'
import RegisterPage from 'pages/Register'
import RegisterSucess from 'pages/Register/Sucess'
import SurveyPage from 'pages/Survey'
import Home from './pages/Home'
import QrHandler from './pages/Redirect/QrHandler'
import SalesHandler from './pages/Redirect/SalesHandler'
import Root from './pages/Root'
import Scan from './pages/Scan'

const routes = [
  {
    path: '',
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'survey',
        element: <SurveyPage />,
      },
      {
        path: 'register-success',
        element: <RegisterSucess />,
      },
      {
        path: ':salesId/representative',
        element: <QrHandler />,
      },
      {
        path: 'sales-handler/:action/:token',
        element: <SalesHandler />,
      },
    ],
  },
  {
    path: 'scan',
    element: <Scan />,
  },
]

export default routes
