import {yupResolver} from '@hookform/resolvers/yup'
import {useMutation} from '@tanstack/react-query'
import cx from 'clsx'
import useSession from 'hooks/useSession'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import api from 'service/core'
import * as Yup from 'yup'

const validation = Yup.object({
  answer1: Yup.string().required('Jawaban wajib diisi'),
  answer2: Yup.string().required('Jawaban wajib diisi'),
  answer3: Yup.string().required('Jawaban wajib diisi'),
})

const SurveyPage = () => {
  const navigate = useNavigate()
  const {registerData, setCookie} = useSession()

  const {isLoading, mutate: registerUser} = useMutation(
    async (json) => {
      try {
        const d = await api.post('/pub/participant/save', json)
        return d?.data?.data
      } catch (error) {
        const msg = error?.response?.data?.message || 'Something went wrong'
        toast.error(msg)
        throw Error(msg)
      }
    },
    {
      onSuccess: (data) => {
        setCookie('registerData', {})
        navigate('/register-success')
      },
    },
  )

  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      answer1: '',
      answer2: '',
      answer3: '',
    },
    mode: 'all',
  })

  const handleOnsubmit = async (values) => {
    const cookieData = {...registerData, ...values}
    setCookie('registerData', cookieData)
    registerUser(cookieData)
  }

  return (
    <section className='pendaftaran'>
      <div className='container-fluid px-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='nav-top-inside'>
              <a href='/register'>
                <i className='icon-chevron_left'></i>
              </a>
              <h2>Survey</h2>
            </div>

            <div className='form-pendaftaran'>
              <p>
                Please complete the following survey to complete your
                registration process.
              </p>
              <form onSubmit={handleSubmit(handleOnsubmit)}>
                <ul className='form-group list-group list-group-numbered'>
                  <li className='mb-2'>
                    Apakah Anda menggunakan teknologi AI dalam pekerjaan Anda?
                    Jika ya, bagaimana Anda menggunakannya?
                  </li>
                  <div className='input-group mb-3 has-validation'>
                    <textarea
                      className={cx('form-control', {
                        'is-invalid': !!errors.answer1,
                      })}
                      {...register('answer1')}
                    />
                    <div className='invalid-feedback'>
                      {errors.answer1?.message}
                    </div>
                  </div>

                  <li className='mb-2'>
                    Jenis data apa yang sedang Anda kelola?
                  </li>
                  <div className='input-group mb-3 has-validation'>
                    <textarea
                      placeholder='Enter your company name'
                      aria-label='Company'
                      aria-describedby='basic-addon1'
                      className={cx('form-control', {
                        'is-invalid': !!errors.answer2,
                      })}
                      {...register('answer2')}
                    />
                    <div className='invalid-feedback'>
                      {errors.answer2?.message}
                    </div>
                  </div>
                  <li className='mb-2'>
                    Apa harapan Anda dari penggunaan AI dalam membantu pekerjaan
                    Anda?
                  </li>
                  <div className='input-group mb-3 has-validation'>
                    <textarea
                      aria-describedby='basic-addon1'
                      className={cx('form-control', {
                        'is-invalid': !!errors.answer3,
                      })}
                      {...register('answer3')}
                    />
                    <div className='invalid-feedback'>
                      {errors.answer3?.message}
                    </div>
                  </div>
                </ul>

                <button
                  type='submit'
                  className='btn btn-primary border-0 w-100'
                  style={{zIndex: 3}}
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SurveyPage
