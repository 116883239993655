import dayjs from 'dayjs'
import {useCookies} from 'react-cookie'
import {session_key} from '../constants'

const useSession = () => {
  const [
    {
      _morinaga_session_key2023,
      sales_promotion_id,
      privacyModal,
      questionsAnwers,
      questionStep,
      registerData,
    },
    setCookie,
  ] = useCookies([
    session_key,
    'sales_promotion_id',
    'privacyModal',
    'questionStep',
    'questionsAnwers',
    'registerData',
  ])

  const setSession = (value) => {
    setCookie('_morinaga_session_key2023', value, {
      expires: dayjs().add(1, 'month').toDate(),
    })
  }

  return {
    session: _morinaga_session_key2023,
    sales_promotion_id,
    privacyModal,
    questionStep,
    questionsAnwers,
    registerData,
    setSession,
    setCookie,
  }
}

export default useSession
