import {asaKreatifLogo} from 'assetConstants'
import bannerTxt from 'assets/img/banner-text.png'
import maskot from 'assets/img/maskot.png'
import Footer from 'components/Footer'
import BottomHomeSection from './components/BottomHomeSection'
import SlideSection from './components/SlideSection'

const Arrow = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    dataLucide='arrow-right'
    class='lucide lucide-arrow-right'
  >
    <path d='M5 12h14'></path>
    <path d='m12 5 7 7-7 7'></path>
  </svg>
)
const Home = () => {
  return (
    <>
      <section className='home-top-banner'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='d-flex nav-top'>
                <a href='/'>
                  <img src={asaKreatifLogo} />
                </a>
              </div>
              <div className='row content-banner' style={{minHeight: '12rem'}}>
                <div className='col-6'>
                  <img src={maskot} alt='Welcome Banner' />
                </div>
                <div className='col-6'>
                  <img
                    src={bannerTxt}
                    alt='Welcome Banner'
                    className='text-img'
                  />
                  <a href='/register' className='btn btn-primary'>
                    Register <Arrow />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SlideSection />
      <BottomHomeSection />
      <Footer />
    </>
  )
}

export default Home
