import merchandiseIC from 'assets/img/thumbs-up.png'
import {useEffect, useRef} from 'react'

const RegisterSucess = () => {
  const topref = useRef()
  useEffect(() => {
    let mounted = true
    if (mounted && topref.current) topref.current.scrollIntoView()

    return () => {
      mounted = false
    }
  }, [])
  return (
    <section id='content-body'>
      <section className='pendaftaran-berhasil'>
        <div className='container-fluid px-0'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='banner-top-daftar text-center '>
                <h3 ref={topref}>
                  Thanks You for <br />
                  Submitting!
                </h3>
                <div className='illustration-top'>
                  <img src={merchandiseIC} />
                </div>
              </div>
              <div className='desc-text-box mt-5'>
                <p>Thank you for filling in the data and survey</p>
                <div className='mt-5'>
                  <a href='/' className='btn btn-new mt-3 '>
                    Back to home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default RegisterSucess
