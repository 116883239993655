import {asaKreatifLogoRed} from 'assetConstants'

const NotFoundPage = () => {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
      <div className='text-center'>
        <h1 className='display-1 fw-bold'>
          <img alt='' src={asaKreatifLogoRed} width='65px' />
        </h1>
        <p className='fs-3'>
          <span className='text-danger'>404</span>, Page not found.
        </p>
        <p className='lead my-3'>
          The page is not exist or it is on maintenance mode.
        </p>
        <a href='/' className='btn btn-primary'>
          Go Home
        </a>
      </div>
    </div>
  )
}

export default NotFoundPage
